
/*宽度限制*/
.full-width{
  width:100%;
}
.width-60{
  width:60px !important;
}
.width-80{
  width:80px !important;

}
.width-100{
  width:100px !important;
}
.width-120{
  width:120px !important;
}
.width-140{
  width:140px !important;
}
.width-150{
  width:150px !important;
}
.width-200{
  width:200px !important;
}
.width-250{
  width:250px !important;
}
.width-300{
  width:300px !important;
}
.width-350{
  width:350px !important;
}
.width-400{
  width:400px !important;
}
.width-520{
  width:520px !important;

}
.width-660{
  width:660px !important;
}

.width-880{
  width:880px !important;
}
/*height高度值*/
.height-150{
  height:150px;
}
.height-175{
  height:175px;
}
.height-200{
  height:200px;
}
.height-225{
  height:225px;
}
.height-250{
  height:250px;
}
.height-275{
  height:275px;
}
.height-300{
  height:300px;
}
.height-325{
  height:325px;
}
.height-350{
  height:350px;
}

.height-375 {
  height: 375px;
}

.height-400 {
  height: 400px;
}

.height-425 {
  height: 425px;
}

.height-450 {
  height: 450px;
}

.height-475{
  height:475px;
}
.height-500 {
  height: 500px;
}
/* 最小高度 */
.minHeight-400{
  min-height:400px !important;
}
