.invoice-env {
	padding: @default-padding/2 0;
	&:extend(.clearfix all);
	
	@media print {
		padding: 0;
	}
	
	.invoice-header {
		border-bottom: 1px solid #f5f5f5;
		padding-bottom: @base-margin;
		&:extend(.clearfix all);
		
		.invoice-logo {
			float: left;
			
			.logo {
				display: block;
				margin-bottom: @base-margin;
			}
			
			ul {
				
				li {
					color: @main-text-color;
					font-size: @font-size-base + 1;
					
					strong {
						color: darken(@main-text-color, 15%);
					}
					
					+ li {
						margin-top: 2px;
					}
				}
			}
		}
		
		.invoice-options {
			float: right;
			width: 180px;
		}
		
		@media screen and (max-width: @screen-xs){
			
			.invoice-logo,
			.invoice-options {
				float: none;
			}
			
			.invoice-options {
				width: auto;
				margin-bottom: @default-padding;
			}
		}
	}
	
	.invoice-details {
		padding-top: @default-padding;
		padding-bottom: @base-padding;
		&:extend(.clearfix all);
		
		.invoice-client-info,
		.invoice-payment-info {
			font-size: @font-size-base + 1;
			.box-sizing(border-box);
			&:extend(.clearfix all);
		
			> strong {
				display: block;
				margin-bottom: @base-padding/2;
				color: darken(@main-text-color, 20%);
			}
			
			strong {
				color: darken(@main-text-color, 15%);
			}
		}
		
		.invoice-client-info {
			float: left;
			width: 60%;
			
			ul {
				float: left;
				margin-right: @base-margin;
			}
			
			@media print {
				width: 50%;
			}
		}
		
		.invoice-payment-info {
			float: right;
			width: 30%;
			
			@media print {
				width: 50%;
				text-align: right;
			}
		}
		
		@media screen and (max-width: @screen-xs){
			
			.invoice-client-info,
			.invoice-payment-info {
				float: none;
				width: 100%;
			}
			
			.invoice-payment-info {
				margin-top: @default-padding/2;
			}
		}
	}
	
	.table {
		margin-top: @default-padding/2;
		margin-bottom: @default-padding/2;
	}
	
	.invoice-totals {
		margin-top: @default-padding;
		&:extend(.clearfix all);
		
		.invoice-bill-info {
			float: left;
			padding-left: @default-padding/2;
		}
		
		.invoice-subtotals-totals {
			float: right;
			text-align: right;
			padding-right: @default-padding/2;
			
			span {
				display: block;
				
				strong {
					color: darken(@main-text-color, 15%);
					display: inline-block;
					min-width: 50px;
				}
			}
			
			hr {
				margin: @base-padding - 2 0;
			}
		}
		
		@media screen and (max-width: @screen-xs){
			
			.invoice-bill-info,
			.invoice-subtotals-totals {
				float: none;
				//text-align: left;
				padding: 0;
			}
			
			.invoice-subtotals-totals {
				margin-bottom: @default-padding;
			}
		}
		
		@media print {
			
			.invoice-bill-info,
			.invoice-subtotals-totals {
				padding: 0;
			}
		}
	}
}