// Left aligned timeline
@p_size: 13px;
@hover_bg: #fff;
@main_color: #303641;
@text_color_light: #737881;
@border: #fff;
@base_margin: @base-margin;
@base_padding: @base-padding;

.page-body .main-content .cbp_tmtimeline {
	
	&:before {
		background: @border;
		width: 5px;
		margin-left: -6px;
	}
	
	> li {
		
		.cbp_tmtime {
			
			> span {
				color: darken(@main-text-color, 25%);
				font-size: 15px;
				
				&:first-child {
					font-weight: bold;
					margin-bottom: 2px;
				}
				
				&:last-child {
					color: @main-text-color;
					.opacity(.8);
					font-size: 12px;
				}
				
				&.large {
					font-size: 17px;
				}
				
				&.hidden + span {
					margin-top: 8px;
				}
			}
		}
		
		.cbp_tmicon {
			background: #ffffff;
			color: @main-text-color;
			.box-shadow(0px 0px 0px 3px @border);
			
			&.timeline-bg-primary { background-color: @brand-primary; color: #fff; }
			&.timeline-bg-success { background-color: @brand-success; color: #fff; }
			&.timeline-bg-info { background-color: @brand-info; color: #fff; }
			&.timeline-bg-danger { background-color: @brand-danger; color: #fff; }
			&.timeline-bg-warning { background-color: @brand-warning; color: #fff; }
			&.timeline-bg-red { background-color: @xe-red; color: #fff; }
			&.timeline-bg-purple { background-color: @xe-purple; color: #fff; }
			&.timeline-bg-secondary { background-color: @secondary-color; color: #fff; }
			&.timeline-bg-gray { background-color: #ddd; color: #777; }
		}
		
		.cbp_tmlabel {
			background: @border;
			color: @text_color_light;
			margin-bottom: 30px;
			padding: @base-margin;
			.border-radius(@border-radius-base);
			
			h2, p {
				color: @text_color_light;
				font-size: @p_size;
				margin: 0;
				line-height: @line-height-base;
			}
			
			p + p {
				margin-top: @base_margin / 2;
			}
			
			h2 {
				font-size: @font-size-h3 - 8;
				
				a {
					color: @main_color;
				}
				
				span {
					.opacity(.6);
				}
			}
			
			blockquote {
				font-size: @font-size-base + 1;
				padding-top: 4px;
				padding-bottom: 4px;
			}
			
			&:after {
				border-right-color: @border;
			}
			
			&.empty {
				background: 0;
				padding: 9px 0;
				margin-bottom: 70px;
				
				&:after {
					visibility: hidden;
				}
			}
		}
	}
}

@media screen and (max-width: 47.2em) {
	
	.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel:after {
		border-right-color: transparent;
		border-bottom-color: #fff;
		left: 10px;
		//border-bottom-color: @hover_bg;
	}
	
	.page-body .main-content .cbp_tmtimeline > li .large {
		font-weight: bold;
		font-size: 16px !important;
	}
	
	.page-body .main-content .cbp_tmtimeline > li .cbp_tmlabel.empty {
		background: @hover_bg;
		padding: 1.7em;
		
		&:after {
			visibility: visible;
		}
	}	
}


// Centered timeline
.timeline-centered {
	@icon_size: 40px;
	
	position: relative;
	margin-bottom: @base_margin;
	&:extend(.clearfix all);
	
	@media screen and (max-width: @screen-sm){
		margin-top: 60px !important;
	}
	
	
	&:before {
		content: '';
		position: absolute;
		display: block;
		width: 4px;
		background: @hover_bg;
		left: 50%;
		top: @icon_size / 2;
		bottom: @icon_size / 2;
		margin-left: -4px;
	}
	
	.timeline-entry {
		@tm_w: 100px;

		position: relative;
		width: 50%;
		float: right;
		margin-bottom: 70px;
		clear: both;
		.clearfix;
		
		&.begin {
			margin-bottom: 0;
		}
		
		&.left-aligned {
			float: left;
			
			.timeline-entry-inner {
				margin-left: 0;
				margin-right: -@icon_size/2 + 2;
				
				.timeline-time {
					left: auto;
					right: -@tm_w - 20;
					text-align: left;
				}
				
				.timeline-icon {
					float: right;
				}
				
				.timeline-label {
					margin-left: 0;
					margin-right: 70px;
					
					&:after {
						left: auto;
						right: 0;
						margin-left: 0;
						margin-right: -9px;
						.rotate(180deg);
					}
				}
			}
		}
		
		.timeline-entry-inner {
			position: relative;
			margin-left: -@icon_size/2 - 2;
			&:extend(.clearfix all);
			
			.timeline-time {
				position: absolute;
				left: -@tm_w;
				text-align: right;
				padding: 10px;
				padding-top: 2px;
				.box-sizing(border-box);
				
				> span {
					display: block;
					color: @main-text-color;
					
					&:first-child {
						font-size: 15px;
						font-weight: bold;
						color: darken(@main-text-color, 15%);
					}
					
					&:last-child {
						font-size: 12px;
					}
				}
			}
			
			.timeline-icon {
				background: #fff;
				color: @text_color_light;
				display: block;
				.size(@icon_size);
				.border-radius(@icon_size/2);
				text-align: center;
				.box-shadow(0px 0px 0px 5px @hover_bg);
				line-height: @icon_size;
				font-size: @p_size + 3;
				float: left;
						
				&.timeline-bg-primary { background-color: @brand-primary; color: #fff; }
				&.timeline-bg-success { background-color: @brand-success; color: #fff; }
				&.timeline-bg-info { background-color: @brand-info; color: #fff; }
				&.timeline-bg-danger { background-color: @brand-danger; color: #fff; }
				&.timeline-bg-warning { background-color: @brand-warning; color: #fff; }
				&.timeline-bg-red { background-color: @xe-red; color: #fff; }
				&.timeline-bg-purple { background-color: @xe-purple; color: #fff; }
				&.timeline-bg-secondary { background-color: @secondary-color; color: #fff; }
			}
			
			.timeline-label {
				position: relative;
				background: @hover_bg;
				padding: 1.7em;
				margin-left: 70px;
				
				&:after {
					content: '';
					display: block;
					position: absolute;
					width: 0px;
					height: 0px;
					border-style: solid;
					border-width: 9px 9px 9px 0;
					border-color: transparent @hover_bg transparent transparent;
					left: 0;
					top: @base_padding;
					margin-left: -9px;

				}
			
				h2, p {
					color: @text_color_light;
					font-size: @p_size;
					margin: 0;
					line-height: @line-height-base;
				}
				
				p + p {
					margin-top: @base_margin / 2;
				}
				
				h2 {
					font-size: @font-size-h3 - 8;
					margin-bottom: @base_padding;
					
					a {
						color: @main_color;
					}
					
					span {
						.opacity(.6);
					}
				}
				
				blockquote {					
					font-size: @font-size-base + 1;
					padding-top: 4px;
					padding-bottom: 4px;
					color: @main-text-color;
				}
			}
		}
	}
}




@media screen and (max-width: 47.2em) {

	.timeline-centered {
		margin-top: 20px;
		
		&:before {
			left: 22px;
		}
	}
	
	.timeline-centered .timeline-entry,
	.timeline-centered .timeline-entry.left-aligned {
		width: 100%;
		float: none;
		
		.timeline-entry-inner {
			margin-left: 0;
			margin-right: 0;
		}
	}
	.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time,
	.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time {
		left: 60px;
		right: auto;
		top: -30px;
		width: auto;
		
		span {
			display: inline-block;
			
			& + span {
				margin-left: 10px;
			}
		}
	}
	
	.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-icon {
		float: left;
	}
	
	.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label {
		margin-left: 70px;
		margin-right: 0;
	}
	
	.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label:after {
		left: 0;
		right: auto;
		margin-left: -9px;
		margin-right: 0;
		.rotate(0deg);
	}
}