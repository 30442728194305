@table-horizontal-padding: 15px;

.table-hover {
  > thead {
    > tr {
      background-color: @table-bg-hover;
      > th {
        border-bottom-width: 1px;
        padding: 8px;
        font-weight: normal;
      }
    }
  }
}

.table-middle-align {
  > thead > tr > th {
    vertical-align: middle;
    font-weight: normal;
  }
  > tbody {

    > tr {

      > td, > th {
        vertical-align: middle;
      }

    }
  }
}

.dataTables_wrapper {
  @border-color: #eeeeee;
  font-size: @font-size-small;
  .btn {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .input-group {
    margin-bottom: @table-condensed-cell-padding;
  }
  .dataTables_length,
  .dataTables_filter,
  .dataTables_info,
  .dataTables_paginate {
    //&:extend(.clearfix all);
  }

  .dataTables_length,
  .dataTables_filter {
    padding-bottom: @base-padding/2;
    &:extend(.clearfix all);
  }

  .dataTables_filter {

    .form-control {
      margin-left: @base-padding;
    }
  }

  .dataTables_info,
  .dataTables_paginate {
    //&:extend(.clearfix all);
    //padding-top: @base-padding;
  }

  .DTTT_container {
    float: right;
    border: 1px solid @border-color;
    position: relative;
    margin-top: -2px;
    margin-left: @base-margin;
    &:extend(.clearfix all);

    + .dataTables_filter {
      float: right;
    }

    .DTTT_button {
      position: relative;
      display: block;
      float: left;
      padding: @base-padding - 3 @base-padding;

      span {
        position: relative;
        z-index: 100;
        cursor: pointer;
      }

      &:hover {
        background: #f5f5f5;

        div {
          background: #f5f5f5;
        }
      }

      + .DTTT_button {
        border-left: 1px solid @border-color;
      }
    }
  }
  table.dataTable {
    margin-top: 0;
    width: 100%;
    thead > tr > th {
      /*padding-left: @table-horizontal-padding;
        padding-right: @table-horizontal-padding;*/
      font-weight: normal;
    }
  }
  tr {

    &.replace-inputs {

      > th, > td {
        vertical-align: top;
      }
    }

    .dataTables_empty {
      //border-bottom: 1px solid @border-color;
    }

    .yadcf-filter-wrapper {
      position: relative;
      margin-top: @base-padding;
      border-top: 1px solid @border-color;
      margin-left: -@table-horizontal-padding;
      margin-right: -@table-horizontal-padding;
      display: block;
      padding: @base-padding @table-horizontal-padding;
      padding-bottom: 0;

      // Reset Button
      .yadcf-filter-reset-button {
        .btn-unstyled;
        position: absolute;
        top: 0;
        right: 0;
        padding: 2px @base-padding/2;
        padding-top: 0;
        background: #f5f5f5;
        color: @main-text-color;
        line-height: 1;
        font-weight: normal;
        font-size: 11px;
        .opacity(0);
      }

      &:hover .yadcf-filter-reset-button {
        .opacity(1);
      }

      // Filter Input
      .yadcf-filter {
        display: block;
        width: 100%;
        margin: 0;

        &[type="text"] {
          background: #fff;
          border: 1px solid @input-border;
          font-weight: normal;
          outline: none;
          padding: 0 @base-padding;
          height: @input-height-small;
          .box-shadow(none);
        }
      }

      .yadcf-filter-wrapper-inner {
        display: table;
        width: 100%;

        .yadcf-filter-range {
          display: table-cell;
          width: 100%;
          font-weight: normal;
          background: #fff;
          border: 1px solid @input-border;
          font-weight: normal;
          outline: none;
          padding: 0 @base-padding/2;
          height: @input-height-base;
          text-align: center;
          .box-shadow(none);
        }

        .yadcf-filter-range-number-seperator {
          padding: 0 @base-padding/2;
          display: table-cell;
          width: 10%;

          &:after {
            content: '–';
            color: @main-text-color;
            font-size: @font-size-small - 1;
            font-weight: normal;
          }
        }
      }
    }

  }

  .table {

    thead {
      > tr {

        .sorting,
        .sorting_asc,
        .sorting_desc {
          background: none;

          /*  &:before {
              font-family: 'fontawesome';
              content: '\f0dc';
              float: right;
              display: block;
              .opacity(.5);
              > th {
                font-weight: normal;
              }
            }*/
        }

        /*  .sorting_asc:before {
            content: '\f0de';
          }

          .sorting_desc:before {
            content: '\f0dd';
          }

          .no-sorting:before {
            display: none;
          }*/
      }
    }
  }
}

table.dataTable {
  margin-top: 0;
  &.fixedHeader-floating > tr > th {
    font-weight: normal;
  }
}

.table {

  > tbody {

    > tr {

      > td {
        > .btn-group {
          display: inline-flex;
          display: -webkit-inline-flex;

          > .btn {
            font-size: @font-size-small;
            margin-bottom: 0;
            padding: 3px 8px;
            .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
          }
          .btn-primary:hover {

          }
          > .dropdown-menu {
            font-size: @font-size-small;
            min-width: 100%;
            padding: 2px 0;
            > li + .divider {
              display: block;
            }
            .divider {
              display: none;
              margin: 2px 0;
            }
            a {
              padding: 3px 10px;
              cursor: pointer;
            }
            .btn {
              width: 100%;
              padding: 3px 10px;
              margin-bottom: 0;
            }
          }
        }

      }

    }
  }
}


// 表格按钮栏
.dataTables-buttons-box {
  display: inline-block;
  margin-bottom: @table-condensed-cell-padding;
  .btn {
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 0;
  }
  .advanced-search-btn {
    padding-left: 10px;
    cursor: pointer;
  }

}

&.advanced-search {
  width: 100%;
}

/* 高级搜索背景样式 */
.advanced-search-block {
  width: 100%;

  padding: @line-group-margin @line-group-margin 0 @line-group-margin;
  background: @table-bg-hover;
  border: 1px solid @table-border-color;
  margin-bottom: @line-group-margin;
  border-radius: @border-radius-base;
  > * {
    text-align: right;
  }
  .selectboxit-container, .select2-container {
    text-align: left;
  }
}