blockquote {
	
	small {
		margin-top: @base-padding;
		display: block;
	}
}
.blockquote {
	position: relative;
	padding: @base-margin @default-padding;
	background: #fff;
	border: 1px solid @default-border-color;
	
	&:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 6px;
		background: #f7f7f7;
	}
	
	&.blockquote-blue { .xenon-blockquote-style(@xe-blue); }
	&.blockquote-red { .xenon-blockquote-style(@xe-red); }
	&.blockquote-success { .xenon-blockquote-style(@brand-success); }
	&.blockquote-warning { .xenon-blockquote-style(@brand-warning); }
	&.blockquote-info { .xenon-blockquote-style(@brand-info); }
	&.blockquote-danger { .xenon-blockquote-style(@brand-danger); }
	&.blockquote-purple { .xenon-blockquote-style(@xe-purple); }
	&.blockquote-black { .xenon-blockquote-style(@xe-black); }
}

.xenon-blockquote-style(@border)
{
	border: 1px solid @border;
	
	&:before {
		background: fade(@border, 10%);
	}
	
	strong {
		color: @border;
	}
}