.profile-env {
	margin-bottom: @default-padding;
	&:extend(.clearfix all);
	
	.user-info-sidebar {
		
		hr {
			border-top-color: darken(#eee, 5%);
			margin: @base-padding 0;
		}
		
		.user-img {
			display: block;
			margin-bottom: @base-padding;
			text-align: center;
			
			img {
				display: inline-block;
				
				&.img-thumbnail {
					padding: 4px;
					border: 0;
				}
			}
		}
		
		.user-name {
			display: block;
			font-size: @font-size-h4 + 2;
			text-align: center;
		}
		
		.user-title {
			display: block;
			color: #999999;
			text-align: center;
			font-size: @font-size-small;
			margin-bottom: @base-margin;
			
			strong, a {
				color: darken(@main-text-color, 10%);
			}
		}
		
		.user-status {
			position: relative;
			display: inline-block;
			background: #FFF;
			top: -2px;
			margin-left: @base-padding/2;
			.size(6px);
			.border-radius(50%);
			
			&.is-online { background-color: @brand-success; }
			&.is-idle { background-color: @brand-warning; }
			&.is-busy { background-color: @xe-red; }
			&.is-offline { background-color: #CCC; }
		}
		
		.user-info-list {
			display: table;
			margin: 0 auto;
			
			li {
				margin: @base-padding 0;
				color: @main-text-color;
				
				i {
					color: darken(@main-text-color, 10%);
					display: inline-block;
					margin-right: 5px;
				}
				
				a, strong {
					color: darken(@main-text-color, 15%);
				}
				
				a:hover {
					color: darken(@main-text-color, 30%);
				}
			}
		}
		
		.user-friends-count {
			display: table;
			margin: @base-margin auto;
			
			li {
				position: relative;
				display: table-cell;
				color: lighten(@main-text-color, 10%);
				font-size: @font-size-small;
				padding: 0 @base-margin;
				
				span {
					display: block;
					font-size: @font-size-h3;
					color: darken(@main-text-color, 20%);
				}
				
				&:first-child {
					padding-left: 0;
				}
				
				&:last-child {
					padding-right: 0;
				}
				
				+ li {
					
					&:after {
						@dist: 15%;
						
						display: block;
						content: '';
						position: absolute;
						left: 0;//-@base-margin;
						top: @dist;
						bottom: @dist;
						width: 1px;
						background: #dddddd;
					}
				}
			}
		}
	}
	
	.profile-post-form {
		position: relative;
		background: #fff;
		border: 1px solid #e4e4e4;
		padding: @default-padding - @base-padding;
		margin-bottom: @default-padding;
			
		&:after {
			@s: 7px;
			
			content: '';
			display: block;
			position: absolute;
			left: -@s - @s*.2 + 1;
			top: 25px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: @s @s+@s*.2 @s 0;
			border-color: transparent #fff transparent transparent;
		}
		
		@media screen and (max-width: @screen-xs-max){ 
			margin-top: @default-padding;
			margin-bottom: @base-margin;
			
			&:after {
				display: none;
			}
		}		
		
		textarea {
			position: relative;
			resize: none;
			z-index: 1;
			border-bottom: 1px solid @background-color;
			.placeholder(lighten(@main-text-color, 15%));
			
			&:focus ~ .post-story-button {
				.opacity(1);
			}
		}
		
		.form-action-buttons {
			margin: 0;
			margin-top: @default-padding/2;
			
			li {
				
				button {
					color: @main-text-color;
					font-size: @font-size-base + 2;
					
					&:hover {
						color: lighten(@text-color, 15%);
					}
					
					&:active {
						color: @secondary-color;
					}
				}
			}
		}
		
		.post-story-button {
			float: right;
			margin-top: -17px;
			padding-left: @base-margin;
			padding-right: @base-margin;
			.transition(~"opacity 150ms linear");
			.opacity(0);
			
			&:active, &:focus {
				.opacity(1);
			}
		}
	}
	
	.block-icon {
		position: absolute;
		right: @base-margin;
		top: @base-margin;
		color: lighten(@main-text-color, 20%);
	}
	
	.user-timeline-stories {
		
		.timeline-story {
			position: relative;
			background: #fff;
			padding: @base-margin;
			margin-bottom: @default-padding;
			
			@media screen and (max-width: @screen-xs-max){
				margin-bottom: @base-margin;
			}
			
			header {
				color: @main-text-color;
				font-size: @font-size-base + 1;
				margin-bottom: @base-padding;
				&:extend(.clearfix all);
				
				.user-img {
					margin-right: @base-margin;
					float: left;
					
					img {
						width: 48px;
					}
				}
				
				.user-details {
					display: block;
					padding-top: @base-padding/2;
				}
				
				a {
					color: darken(@main-text-color, 20%);
					
					&:hover {
						color: darken(@main-text-color, 30%);
					}
				}
				
				time {
					display: block;
					font-size: @font-size-small;
					color: lighten(@main-text-color, 10%);
				}
			}
			
			.story-content {
				display: block;
				padding-left: 70px;
				
				@media screen and (max-width: @screen-xs-max){
					padding-left: 0;
				}
				
				p {
					font-size: @font-size-base + 1;
				}
			}
			
			@media screen and (max-width: @screen-xs-max){
				> .story-content {
					margin-top: @base-margin;
				}
			}
				
			.story-audio-item {
				background: #f8f8f8;
				padding: @default-padding;
				margin: @base-padding -@base-margin;
				margin-top: @base-margin;
				
				.story-content {
					margin-left: -@default-padding/2 + 2;
				}
				
				.audio-track-info {
					padding-bottom: @default-padding/2;
					&:extend(.clearfix all);
					
					.artist-info,
					.track-length {
						color: @main-text-color;
					}
					
					.artist-info {
						float: left;
					}
					
					.track-length {
						float: right;
					}
				}
				
				.audio-track-timeline {
					width: 100%;
					display: table;
					
					.play-pause,
					.track-timeline,
					.track-volume {
						display: table-cell;
						vertical-align: middle;
					}
					
					.play-pause {
						width: 75px;
					}
					
					.track-volume {
						width: 30px;
						text-align: right;
					}
					
					.track-timeline {
						
						.track-timeline-empty {
							display: block;
							height: 2px;
							background: #dedede;
							position: relative;
							
							.track-fill {
								position: absolute;
								left: 0;
								top: 0;
								bottom: 0;
								background: @brand-primary;
							}
						}
					}
				}
			}
			
			.story-options-links {
				padding-top: @base-padding;
				
				a {
					display: inline-block;
					color: @main-text-color;
					margin-right: 30px;
					
					span {
						display: inline-block;
						.opacity(.9);
					}
					
					&:hover {
						color: darken(@main-text-color, 25%);
					}
					
					&.liked {
						color: #ed3434;
					}
				}
			}
			
			.story-comments {
				border-top: 1px solid #eeeeee;
				margin-top: @base-margin;
				padding-top: @base-padding;
				
				.story-comment {
					margin-top: @base-padding;
					
					.comment-user-img {
						display: block;
						float: left;
						
						img {
							width: 32px;
						}
					}
					
					.story-comment-content {
						margin-left: 50px;
						padding-bottom: @base-padding/2;
						padding-left: 2px;
						border-bottom: 1px solid #eeeeee;
						
						.story-comment-user-name {
							display: block;
							font-size: @font-size-base + 1;
							color: darken(@main-text-color, 25%);
							margin-bottom: @base-padding/2;
							
							time {
								display: inline-block;
								margin-left: @base-padding/2;
								font-size: @font-size-small - 1;
								color: #ddd;
							}
						}
						
						p {
							font-size: @font-size-base;
						}
					}
				}
					
				li:last-child .story-comment .story-comment-content {
					border-bottom: 0;
				}
			}
			
			.story-comment-form {
				margin-top: @base-margin;
				textarea {
					border: 1px solid #eee;
					padding: @base-padding;
					max-height: 100px;
					margin-bottom: @base-padding;
				}
			}
			
			.story-checkin {
				margin-bottom: @base-padding;
				border: 1px solid #eeeeee;
				padding: @base-padding;
			}
			
			.story-album {
				border: 1px solid #eeeeee;
				padding: @base-padding;
				margin-bottom: @base-margin;
				&:extend(.clearfix all);
				
				.col-1,
				.col-2 {
					display: block;
					float: left;
					width: 50%;
				}
				
				@spacing: 6px;
				
				.col-1 {
					padding-right: @spacing;
				}
				
				.col-2 {
					padding-left: @spacing;
				}
				
				@media screen and (max-width: @screen-xs-max){
					padding-bottom: 0;
					
					.col-1, .col-2 {
						padding: 0;
						width: 100%;
						float: none;
						margin-bottom: @base-padding;
					}
				}
			}
			
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}