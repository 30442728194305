.col-428bca {
  color: #428bca
}

.col-3276b1 {
  color: #3276b1
}

.col-288acb {
  color: #288acb
}

.col-737383 {
  color: #737383
}

.col-5cb85c {
  color: #5cb85c
}

.col-47a447 {
  color: #47a447
}

.col-5bc0de {
  color: #5bc0de
}

.col-39b3d7 {
  color: #39b3d7
}

.col-e76c00 {
  color: #e76c00
}

.col-c4630e {
  color: #c4630e
}

.col-d9534f {
  color: #d9534f
}

.col-d2322d {
  color: #d2322d
}

.col-c3cdd9 {
  color: #c3cdd9
}

.col-979898 {
  color: #979898
}

.col-fff {
  color: #fff
}

.col-000 {
  color: #000
}

.col-808b9c {
  color: #808b9c
}

.col-ff0000 {
  color: red
}

.col-ffae00 {
  color: #ffae00
}

.col-f48c12 {
  color: #f48c12
}

.col-00fd22 {
  color: #00fd22
}

.col-0051fd {
  color: #0051fd
}

.col-e100fd {
  color: #e100fd
}