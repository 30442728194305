@page-error-width: 700px;

.page-error {
	background: #fff;
	width: @page-error-width;
	padding: @default-padding + @base-margin;
	margin-bottom: @base-margin;
	
	@media screen and (max-width: @screen-sm-max){
		width: 90%;
		
		.main-content & {
			width: 100%;
		}
	}
	
	&.centered {
		margin-left: auto;
		margin-right: auto;
	}
	
	.error-symbol {
		font-size: 48px;
		line-height: 1;
		
		i {
			line-height: 1;
		}
	}
	
	h2 {
		font-size: @font-size-h2 + 8;
		margin-bottom: @base-margin;
		
		small {
			display: block;
			padding-top: @base-padding;
			color: #bcbcbc;
			font-size: 50%;
		}
	}
	
	.page-error-env > & {
		margin-top: 50px;
	}
}

.page-error-search {
	width: @page-error-width;
	
	@media screen and (max-width: @screen-sm-max){
		width: 90%;
		
		.main-content & {
			width: 100%;
		}
	}
	
	&.centered {
		margin-left: auto;
		margin-right: auto;
	}
	
	form {
		position: relative;
		
		button[type="submit"] {
			display: block;
			position: absolute;
			right: 0;
			top: 0;
			line-height: @input-height-base;
			padding: 0 @base-padding;
			font-size: @font-size-small;
			color: lighten(@main-text-color, 5%);
		}
		
		&.form-half {
			width: 45%;
			margin-left: auto;
			margin-right: auto;
		}
		
		.input-lg + button[type="submit"] {
			line-height: @input-height-large;
			padding: 0 @base-padding+5;
		}
	}
	
	.go-back {
		display: block;
		text-align: center;
		color: lighten(@main-text-color, 8%);
		margin: @default-padding/2 0;
		
		&:hover {
			color: @main-text-color;
		}
	}
}