.nui-steps {
  font-size: 0;
  overflow: hidden;
  line-height: 0;
  margin: @steps-space-top 0;
  .wrap {
    display: inline-block;
  }
  .wrap > div {
    width: @steps-width;
    height: @steps-height;
    display: inline-block;
    line-height: @steps-height;
    vertical-align: top;
    font-size: @steps-font-size;
    position: relative;
    > label {
      margin-left: @steps-height / 2 + 10px;
      cursor: default;
    }
  }
  .triangle-right {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width:@steps-height / 2;
    position: absolute;
    right: -@steps-height + 1; //加一个1px，fix wrap带来的宽度
    z-index: 1;
  }
  .triangle-right-bg {
    .triangle-right;
    border-width:@steps-height / 2 ;
    right: -@steps-height - 2;
    border-color: transparent transparent transparent #FFF;
  }

  .round {
    display: inline-block;
    width: @steps-font-size + 4;
    height: @steps-font-size + 4;
    .border-radius((@steps-font-size + 4) / 2);
    text-align: center;
    line-height: @steps-font-size + 4;
  }
  .text {
    float: right;
    width: @steps-height * 4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .round + span:before {
    content: '\00a0';
  }
  .finished {
    background-color:@steps-finished-color;
    color:@steps-finished-text-color;
    .triangle-right {
      border-color: transparent transparent transparent@steps-finished-color;
    }
    .round {
      background-color: @steps-round-color;
      background-color: transparent\9;
      color:@steps-finished-color;
      > i {
        color:@steps-finished-color;
        font-size: @steps-font-size;
      }
    }
  }
  .current {
    background-color: @steps-current-color;
    color: @steps-finished-text-color;
    .triangle-right {
      border-color: transparent transparent transparent @steps-current-color;
    }
    .round {
      background-color: @steps-round-color;
      color: @steps-current-color;
      color: #FFF\9;
      background-color: transparent\9;
    }
  }
  .todo {
    background-color:@steps-todo-color;
    color :@steps-todo-text-color;
    .triangle-right {
      border-color: transparent transparent transparent@steps-todo-color;
    }
    .round {
      background-color: @steps-round-color;
      background-color: transparent\9;
    }
  }
}

.steps-large {
  .wrap > div {
    font-size: @steps-size-large;
    width: @steps-width * 1.25;
    height:@steps-height * 1.25;
    line-height:@steps-height * 1.25;
    > label {
      font-size: @steps-size-large;
      margin-left:@steps-height * 1.25 / 2 + 10px;
    }
  }
  .triangle-right {
    border-width:@steps-height * 1.25 / 2;
    right: -@steps-height * 1.25 + 1;
  }
  .triangle-right-bg {
    border-width:@steps-height * 1.25 / 2 ;
    right: -@steps-height * 1.25 - 2;
  }
  .round {
    width: @steps-size-large + 4;
    height: @steps-size-large + 4;
    line-height: @steps-size-large + 4;
    .border-radius((@steps-size-large + 4) / 2);

  }
}
.steps-auto {
  display: table;
  width: 100%;
  .wrap  {
    display: table-cell;
    > div {
      width: 100%;
    }

  }
}
.nui-steps-round {
  font-size: 0;
  overflow: hidden;
  line-height: 0;
  margin: @steps-space-top 0;
  padding: 0px @steps-font-size / 2;
  > div {
    display: inline-block;
    vertical-align: top;
    position: relative;
    .wrap {
      .clearfix;
    }
    > label {
      display: inline-block;
      .text-overflow();
      font-size: @steps-font-size;
      line-height: @steps-font-size;
      height: @steps-font-size;
      margin-top: @steps-font-size / 2;
      color:@steps-round-finished-text-color;
      cursor: default;
      text-align: center;
      width:50%;
      margin-left:-25%;
      position: relative;
      left:15px;
    }
    &:first-child > label{
      width:auto;
      max-width: 50%;
      margin-left:0;
      left:0;
    }
    &:last-child, &.last {
      width:30px !important;
      > label {
        position: absolute;
        width:auto;
        margin-left:0;
        left:auto;
        right:0;
      }
    }
    .round {
      width: 22px;
      height: 22px;
      .border-radius(15px);
      display: inline-block;
      vertical-align: middle;
      background-clip: border-box;
      box-sizing: content-box;
      font-size: @steps-font-size;
      color: #FFF;
      line-height: 22px;
      text-align: center;
      float: left;
    }
    .bar {
      margin: 10px 10px 0 40px;
      width: 200px;
      height: 6px;
      vertical-align: middle;
      .border-radius(4px);
    }
  }
  > .finished {
    .round {
      border: 4px@steps-finished-color solid;
      background-color:@steps-finished-color;
      color:@steps-finished-text-color;
    }
    .bar {
      background-color:@steps-finished-color;
    }
  }
  > .current {
    .round {
      border: 4px @steps-current-color solid;
      background-color: @steps-current-color;
    }
    .bar {
      background-color: @steps-current-color;
    }
  }
  > .todo {
    > label {
      color:@steps-todo-text-color;
    }
    .round {
      border: 4px @steps-round-todo-borde-color solid;
      background-color: #FFF;
      color:@steps-todo-text-color;
    }
    .bar {
      background-color:@steps-todo-color;
    }
  }

}

.steps-round-auto {
  display: table;
  width: 100%;
  > div {
    display: table-cell;
    .bar {
      width: auto;
    }
  }
}
.steps-3 {
  > div {
    width: 50%;
  }
}
.steps-4 {
  > div {
    width: 33%;
  }
}
.steps-5 {
  > div {
    width: 25%;
  }
}
.steps-6 {
  > div {
    width: 20%;
  }
}
.steps-7 {
  > div {
    width: 16%;
  }
}