/* 解决轮播表格占位问题 */
.pull-right, .collapse {
  + .sitebanner {
    float: left;
    width: 100%;
  }
}

/* 店铺图标 */
.shopflat {
  a {
    display: inline-block;
    margin-right: 5px;
    margin-top: 5px;
    width: 16px;
    height: 16px;
    background: url(../images/icons/platform.png) no-repeat;
    cursor: pointer;
  }

  .flat-16 {
    background-position: 0 -440px
  }

  .flat-7 {
    background-position: 0 -20px
  }

  .flat-6 {
    background-position: 0 -40px
  }

  .flat-1 {
    background-position: 0 -60px
  }

  .flat-8 {
    background-position: 0 -80px
  }

  .flat-9 {
    background-position: 0 -100px
  }

  .flat-3 {
    background-position: 0 -120px
  }

  .flat-10 {
    background-position: 0 -140px
  }

  .flat-11 {
    background-position: 0 -160px
  }

  .flat-5 {
    background-position: 0 -180px
  }

  .flat-12 {
    background-position: 0 -200px
  }

  .flat-2 {
    background-position: 0 -220px
  }

  .flat-13 {
    background-position: 0 -240px
  }

  .flat-23 {
    background-position: 0 -260px
  }
/*  （官,微,W,A）图标/begin */
  .flat-24 {
    background-position: 0 -360px
  }
  .flat-25 {
    background-position: 0 -420px
  }
  .flat-26 {
    background-position: 0 -400px
  }
  .flat-14 {
  background-position: 0 -380px
  }
  /*  （官,微,A，w）图标/end */
 /*  -未知问号-图标- */
  .question-mark {
    background-position: 0 -440px;
  }
}

/* 左右分栏 */
.col-ax-l {
  width: 300px;
  float: left;
  position: relative;
  z-index: 2;
}

.col-ax-r {
  margin-left: -315px;
  float: right;
  width: 100%;
  padding-left: 315px;
}
/* 菜单部分图标样式 */
.sidebar-menu .main-menu a>i.font-small{
  font-size: 10px;
}

/* 图表打印导出样式调整 */
.highcharts-container hr {
  margin-top: @padding-base-vertical;
  margin-bottom: @padding-base-vertical;
}

/*表格里面的商品图片大小*/
.table .import-img{
  width: 60px;
  height: 60px;
}

/* 店铺选择控件间隙修复 */
.ui-form .box2 .btn+.btn, .ui-form .box1 .btn+.btn{
  margin-left:0;
}

.ui-form .box1 .form-control,.ui-form .box2 .form-control{
  display:block;
}

.ui-form .bootstrap-duallistbox-container label{
  margin-bottom:5px;
}
.ui-form .bootstrap-duallistbox-container .btn{
  margin-bottom: 5px;
}

/* panel阴影 */
.shadow-part{
  box-shadow: 1px 1px 2px #ccc;
}
