// ECRP 新菜单相关参数 20160426
//主色 深蓝
@ecrpmenu-bg:#0e4ea9;
//配色 浅蓝 字体颜色等
@ecrpmenu-color:#155abb;
//配色 顶栏背景
@ecrpmenu-barbg:#252f40;
// 字体颜色
@ecrpmenu-color-white:#fff;
@ecrpmenu-color-blue:#a9c5ea;

// TODO 需要修改变量定义

.three-level-menu {
  padding-bottom: @base-padding;
  a {
    color: @ecrpmenu-color;
    border: 1px solid @ecrpmenu-color;
    margin-right: @base-padding;
    &:hover, &.active {
      background: @ecrpmenu-color;
      color: @ecrpmenu-color-white;
    }
  }
}
