.nodata-part{
  text-align: center;
  &.nodata-middle-size{
    padding: @middle-tips-padding 0;
    font-size: @tips-font-size;
    i {
      font-size: @tips-font-size;
    }
  }
  &.nodata-small-size{
    padding: @small-tips-padding 0;
    font-size: @small-text-size;
    i {
      font-size: @tips-small-font;
      vertical-align: -3px;
    }
  }
  &.nodata-large-size{
    padding: @large-tips-padding 0;
    font-size: @tips-font-size;
    i {
      font-size: @tips-font-size;
    }
  }

  .nodata-tips{
    padding-left:5px;
  }
}